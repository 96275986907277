import { notificationAdminConstants } from "../constants";

const defaultData = {
  list: [],
  count: 0,
  loading: true,
  filter: {
    idSite: "",
    osDevice: "",
    type: "",
  },
  pages: 0,
  limit: 20,
  refresh: false,
};

const initialState = {
  data: defaultData,
};

export default (state = { data: defaultData }, action = {}) => {
  let lastRowOfPages = false;
  switch (action.type) {
    case notificationAdminConstants.LOAD_NOTIF_LIST:
      const { list, count } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          list,
          count,
          loading: false,
        },
      };
    case notificationAdminConstants.UPDATE_FILTER:
      return {
        ...state,
        data: {
          ...state.data,
          loading: true,
          filter: {
            ...state.data.filter,
            ...action.payload,
          },
          pages: 0,
        },
      };
    case notificationAdminConstants.SET_PAGE:
      const { pages } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          pages,
          loading: true,
          filter: {
            ...state.data.filter,
          },
        },
      };
    case notificationAdminConstants.SET_LIMIT:
      const { limit } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          limit,
          pages: 0,
          loading: true,
          filter: {
            ...state.data.filter,
          },
        },
      };
    case notificationAdminConstants.REFRESH_NOTIF:
      return {
        ...state,
        data: {
          ...state.data,
          refresh: !state.data.refresh,
          loading: true,
          filter: {
            ...state.data.filter,
          },
        },
      };
    case notificationAdminConstants.PLAY_STOP_NOTIF:
      const { actions, status } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          list: state.data.list.map((notif) =>
            notif.id === action.payload.id ? { ...notif, actions, status } : notif
          ),
          filter: {
            ...state.data.filter,
          },
        },
      };
    case notificationAdminConstants.DELETE_NOTIF:
      if (state.data.pages > 0 && state.data.list.length === 1) lastRowOfPages = true;

      return {
        ...state,
        data: {
          ...state.data,
          list: state.data.list.filter((notif) => notif.id !== action.payload.id),
          count: state.data.count - 1,
          pages: lastRowOfPages ? state.data.pages - 1 : state.data.pages,
          filter: {
            ...state.data.filter,
          },
        },
      };
    case notificationAdminConstants.DUPLICATE_NOTIF:
      if (state.data.list.length === 20) lastRowOfPages = true;

      const newObj = state.data.list.find((element) => element.id === action.payload.id);
      const newTab = [...state.data.list];
      newTab.push(newObj);

      return {
        ...state,
        data: {
          ...state.data,
          list: [...newTab],
          count: state.data.count + 1,
          pages: lastRowOfPages ? state.data.pages + 1 : state.data.pages,
          filter: {
            ...state.data.filter,
          },
        },
      };
    default:
      return state;
  }
};
