import API from "helpers/api";

const getTesterFilterList = async () => {
  const path = `/services/backOffice/tester/getFilterTester`;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

const getSearchTester = async (value) => {
  const path = `/services/backOffice/tester/search?filter=${value}`;
  if (value.length >= 2) {
    const res = await API.get(path);
    const dt = res.data.data;
    return dt;
  }
};

const postSubOrUnsubCitizenResponder = async (udid) => {
  const path = `/services/backOffice/tester/subUnsub`;
  const res = await API.post(path, { udid });
  const dt = res.data.data;
  return dt;
};

const postNotificationTester = async (udid) => {
  const path = `/services/backoffice/notification/notifForTester`;
  const res = await API.post(path, { udid });
  const dt = res.data.data;
  return dt;
};

export const testerManagementService = {
  getTesterFilterList,
  postSubOrUnsubCitizenResponder,
  getSearchTester,
  postNotificationTester,
};
