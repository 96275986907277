import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { w3cwebsocket as W3CWebSocket } from "websocket";

import config from "config.js";
import { getAlerts } from "redux/actions/ongoingAlertsAction";
import { getNotification } from "redux/actions/notificationAction";
import { setMessage } from "redux/actions/messageAction";

/**
 * Hook gérant la connexion au WebSocket général si l'utilisateur est connecté.
 * Le Websocket général prévient s'il faut mettre à jour :
 * - La notification
 * - Les alertes en cours
 */
export default function useSubscribeToGeneralWebSocket() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userState);
  const [connectedToWebSocket, setConnectedToWebSocket] = useState(false);

  function refreshOngoingAlerts() {
    dispatch(getAlerts({ close: 0, status: 2 })).catch((error) =>
      dispatch(setMessage({ msg: `App.js - GET alerts - ${error}`, msgType: "error" }))
    );
  }

  function refreshNotification() {
    dispatch(getNotification("fdbs_notif", t("localLanguage"))).catch((error) =>
      dispatch(setMessage({ msg: `App.js - GET notifications - ${error}`, msgType: "error" }))
    );
  }

  useEffect(() => {
    const wsClient = new W3CWebSocket(config.WEBSOCKET_SITE_URL);

    function openConnection() {
      wsClient.onopen = () => {
        wsClient.send(
          JSON.stringify({
            action: "setName",
            name: currentUser.user.accountName,
            idAccount: currentUser.user.siteId.toString(),
            env: config.WEBSOCKET_ENV,
          })
        );
        setConnectedToWebSocket(true);
      };
    }

    function monitoringNewMessages() {
      wsClient.onmessage = (e) => {
        const message = JSON.parse(e.data).eventMsg;
        switch (message) {
          case "REFRESH_NOTIFS":
            refreshNotification();
            break;
          case "REFRESH_ALERTS":
            refreshOngoingAlerts();
          default:
            break;
        }
      };
    }

    function closeConnection() {
      if (connectedToWebSocket) {
        wsClient.onclose = () => {
          setConnectedToWebSocket(false);
        };
      }
    }

    if (currentUser.isLoggedIn) {
      refreshOngoingAlerts();
      refreshNotification();

      openConnection();
      monitoringNewMessages();
    } else {
      closeConnection();
    }
    return () => {
      closeConnection();
    };
  }, [currentUser.isLoggedIn]);
}
