export const notificationAdminConstants = {
  LOAD_NOTIF_LIST: "LOAD_NOTIF_LIST",
  UPDATE_FILTER: "UPDATE_FILTER",
  SET_PAGE: "SET_PAGE",
  SET_LIMIT: "SET_LIMIT",
  REFRESH_NOTIF: "REFRESH_NOTIF",
  PLAY_STOP_NOTIF: "PLAY_STOP_NOTIF",
  DELETE_NOTIF: "DELETE_NOTIF",
  DUPLICATE_NOTIF: "DUPLICATE_NOTIF",
};
