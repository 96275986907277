import API from "helpers/api";

const getAdminNotifications = async (params) => {
  const { type, osDevice, idSite, limit, pages } = params;
  const queryPage = pages + 1;
  const constructQuery = `?idSite=${idSite}&osDevice=${osDevice}&type=${type}&limit=${limit}&page=${queryPage}`;
  const path = `/services/backoffice/admin/notification/getAdminNotifications${constructQuery}`;
  const res = await API.get(path);
  const dt = res.data.data;

  return dt;
};

const playOrStopNotification = async (id) => {
  const path = "/services/backoffice/admin/notification/playOrStopNotification";
  const res = await API.post(path, { id });
  const dt = res.data.data;

  return dt;
};

const deleteAdminNotification = async (id) => {
  const path = `/services/backoffice/admin/notification/deleteAdminNotification/${id}`;
  const res = await API.delete(path);
  const dt = res.data.data;

  return dt;
};

const duplicateDraft = async (id) => {
  const path = "/services/backoffice/admin/notification/duplicateDraft";
  const res = await API.post(path, { id });
  const dt = res.data.data;

  return dt;
};

const getDraftNotificationForEditing = async (id) => {
  const path = `/services/backoffice/admin/notification/getDraftNotification?id=${id}`;
  const res = await API.get(path);
  const dt = res.data.data;

  return dt;
};

const getPotentialCitizen = async (query) => {
  const path = `/services/backoffice/admin/notification/getPotentialCitizens`;

  const res = await API.post(path, query);
  const dt = res.data.data;

  return dt;
};

const getCitizenByPhoneNumber = async (query) => {
  const { filter, idSite, osDevice } = query;
  const path = `/services/backOffice/admin/notification/getNoCitizen?filter=${filter}&idSite=${idSite}&osDevice=${osDevice}`;
  const res = await API.get(path);
  const dt = res.data.data;

  return dt;
};

const postAdminNotification = async (query) => {
  const path = `/services/backoffice/admin/notification/postAdminNotification`;
  const res = await API.post(path, query);
  const dt = res.data.data;

  return dt;
};

const launchTestNotificationPush = async (query) => {
  const path = `/services/backOffice/admin/notification/launchTestNotificationPush`;
  const res = await API.post(path, query);
  const dt = res.data.data;

  return dt;
};

export const adminService = {
  getAdminNotifications,
  playOrStopNotification,
  deleteAdminNotification,
  duplicateDraft,
  getDraftNotificationForEditing,
  getPotentialCitizen,
  getCitizenByPhoneNumber,
  postAdminNotification,
  launchTestNotificationPush,
};
