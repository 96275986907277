import API from "helpers/api";

const getAlertOptions = async () => {
  const path = `services/emergency/v2/options`;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

const settingUpAlert = async (lon, lat, address, addressInfo, countryCode, alertType, alertId) => {
  const path = `/services/emergency/v2/alertSettingUp`;
  const res = await API.put(path, {
    alertId,
    lon,
    lat,
    address,
    addressInfo,
    countryCode,
    alertType,
  });
  const dt = res.data.data;
  return dt;
};

const alert = async (alertId, lon, lat, address, addressInfo, alertType) => {
  const path = `/services/emergency/v2/alertLaunch`;
  const res = await API.post(path, {
    alertId,
    lon,
    lat,
    address,
    addressInfo,
    alertType,
  });
  const dt = res.data.data;
  return dt;
};

const getAlert = async (alertId) => {
  const path = `/services/emergency/v2/alerts/${alertId}`;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

/**
 * Récupère une liste d'alertes
 * @param {Object} filter - Filtres d'alertes.
 * @param {Number} filter.close - État d'ouverture d'alerte désiré.
 * @param {Number} filter.status - Statut d'alerte désiré.
 * @returns {JSON} Retourne les alertes demandées.
 */
const getAlerts = async (filter = {}) => {
  const queryFilter = Object.entries(filter)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  const path = `/services/emergency/v2/alerts${queryFilter ? `?${queryFilter}` : ""}`;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

const onCall = async (alertId) => {
  const path = `/services/emergency/v2/citizenRespondersOnCall?alertId=${alertId}&lang=fr`; // TODO Add language
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

const updtAlert = async (alertId, lon, lat, address, addressInfo) => {
  const path = `/services/emergency/v2/alert/${alertId}`;
  const res = await API.post(path, {
    lon,
    lat,
    address,
    addressInfo,
  });
  const dt = res.data.data;
  return dt;
};

const closeAlert = async (alertId) => {
  const path = `/services/emergency/v2/alertClose`;

  const res = await API.post(path, {
    alertId,
  });
  const dt = res.data.data;
  return dt;
};

const stopCitizenResponder = async (alertId, id) => {
  const path = `/services/emergency/v2/stopCitizenResponder`;

  const res = await API.post(path, {
    alertId,
    id,
  });
  const dt = res.data.data;
  return dt;
};

const startCitizenResponder = async (alertId, id) => {
  const path = `/services/emergency/v2/startCitizenResponder`;

  const res = await API.post(path, {
    alertId,
    id,
  });
  const dt = res.data.data;
  return dt;
};

/**
 * Récupère un historique des alertes
 * @param {Object} query - Filtre des alertes.
 * @param {Number} filter.pages - page demandé.
 * @param {Number} filter.limit - Nombre d'élements maximum par page.
 * @param {String} filter.lang - langue utilisée dans le backOffice.
 * @param {Number} filter.alertType - type d'intervention.
 * @param {String} filter.address - adresse de l'alerte.
 * @param {String} filter.startDate - Date de début des alertes.
 * @param {String} filter.endDate - Date de fin des alertes.
 * @param {String} filter.order - ordre croissant / décroissant des dates
 * @returns {JSON} Retourne l'historique des alertes et le nombre total d'alerte pour le filtre demandé.
 */

const getHistoryAlert = async (query) => {
  const { pages, limit, lang, alertType, address, startDate, endDate, order } = query;
  const queryPage = pages + 1;
  const constructQuery = `?startDate=${startDate}&endDate=${endDate}&address=${address}&alertType=${alertType}&limit=${limit}&page=${queryPage}&lang=${lang}&order=${order}`;
  const path = "/services/backoffice/alert/getAlertHistory" + constructQuery;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

/**
 * Envoie un fichier excel de l'historique des alertes
 * @param {Object} query - Filtre des alertes.
 * @param {String} filter.lang - langue utilisée dans le backOffice.
 * @param {Number} filter.alertType - type d'intervention.
 * @param {String} filter.address - adresse de l'alerte.
 * @param {String} filter.startDate - Date de début des alertes.
 * @param {String} filter.endDate - Date de fin des alertes.
 * @param {String} filter.type - choix entre les alertes uniquement ou les alertes avec les citoyens sauveteurs
 * @param {String} filter.order - ordre croissant / décroissant des dates
 * @returns {JSON} Retourne un fichier excel en téléchargement de l'historique des alertes pour le filtre
 */

const exportHistoryAlert = async (query) => {
  const { type, lang, alertType, address, endDate, startDate, order } = query;
  const path = `/services/backoffice/alert/exportAlertHistory`;

  const res = await API.post(path, {
    type,
    lang,
    alertType,
    address,
    endDate,
    startDate,
    order,
  });
  const dt = res.data.data;
  return dt;
};

export const alertService = {
  getAlertOptions,
  settingUpAlert,
  alert,
  getAlert,
  getAlerts,
  updtAlert,
  onCall,
  closeAlert,
  stopCitizenResponder,
  startCitizenResponder,
  getHistoryAlert,
  exportHistoryAlert,
};
