import { combineReducers } from "redux";
import userReducer from "redux/reducers/userReducer";
import messageReducer from "redux/reducers/messageReducer";
// import permissionReducer from "redux/reducers/permissionReducer";
import accountReducer from "redux/reducers/accountReducer";
import alertReducer from "redux/reducers/alertReducer";
import aedReducer from "redux/reducers/aedReducer";
import passwordReducer from "redux/reducers/passwordReducer";
import notificationReducer from "redux/reducers/notificationReducer";
import ongoingAlertsReducer from "redux/reducers/ongoingAlertsReducer";
import testerManagementReducer from "redux/reducers/testerManagementReducer";
import historyAlertReducer from "redux/reducers/historyAlertReducer";
import refreshDataReducer from "redux/reducers/refreshDataReducer";
import notificationAdminReducer from "./notificationAdminReducer";

export default combineReducers({
  userState: userReducer,
  // permissionState: permissionReducer,
  messageState: messageReducer,
  accountState: accountReducer,
  aedState: aedReducer,
  alertState: alertReducer,
  ongoingAlertsState: ongoingAlertsReducer,
  passwordState: passwordReducer,
  notificationState: notificationReducer,
  testerManagementState: testerManagementReducer,
  historyAlertState: historyAlertReducer,
  refreshDataState: refreshDataReducer,
  notificationAdminState: notificationAdminReducer,
});
