// /**
// =========================================================
// * Soft UI Dashboard PRO React - v4.0.2
// =========================================================

// * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
// * Copyright 2023 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

//  =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

// /**
//   All of the routes for the Soft UI Dashboard PRO Material are added here,
//   You can add a new route, customize the routes and delete the routes here.

//   Once you add a new route on this file it will be visible automatically on
//   the Sidenav.

//   For adding a new route you can follow the existing routes in the routes array.

//   1. The `type` key with the `collapse` value is used for a route.
//   2. The `type` key with the `title` value is used for a title inside the Sidenav.
//   3. The `type` key with the `divider` value is used for a divider between Sidenav items.
//   4. The `type` key with the `single` value is used for route without collpase.
//   5. The `type` key with the `hidden` value is used for hide items.

//   4. The `name` key is used for the name of the route on the Sidenav.
//   5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
//   6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
//   7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
//   inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
//   8. The `route` key is used to store the route location which is used for the react router.
//   9. The `href` key is used to store the external links location.
//   10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
//   10. The `component` key is used to store the component of its route.
// */

import { lazy } from "react";

// // Soft UI Dashboard PRO React layouts
const DefaultLazy = lazy(() => import("layouts/dashboards/default"));

//Alert
const AlertLazy = lazy(() => import("fdbs/modules/alert/alertCall/AlertPageLazy"));
const AlertDemoLazy = lazy(() => import("fdbs/modules/alert/alertCall/AlertDemoPageLazy"));
const AlertDashboardLazy = lazy(() => import("fdbs/modules/alert/alertDashboard/AlertDashboard"));
const TesterLazy = lazy(() => import("fdbs/modules/alert/testerManagement"));
const HistoryLazy = lazy(() => import("fdbs/modules/alert/history"));
const CitizenAlertOneFormLazy = lazy(() =>
  import("fdbs/modules/alert/citizenForm/CitizenAlertOneForm/CitizenAlertOneForm")
);

// Authentication
const SignInLazy = lazy(() => import("fdbs/modules/authentication/sign-in"));
const ResetPwdLazy = lazy(() => import("fdbs/modules/authentication/reset-password"));
const RenewPwdLazy = lazy(() => import("fdbs/modules/authentication/renew-password"));
const UpdtPwdLazy = lazy(() => import("fdbs/modules/authentication/updt-password"));

// Error
const Error500Lazy = lazy(() => import("fdbs/modules/authentication/error/500"));

// AED
const AedImportLazy = lazy(() => import("fdbs/modules/aed/import"));

// Admin
const NotificationListLazy = lazy(() =>
  import("fdbs/modules/admin/notification/NotificationsList")
);
const NotificationAddLazy = lazy(() => import("fdbs/modules/admin/notification/NotificationsAdd"));

import Heart from "fdbs/components/Icons/Heart";

const adminRoutes = [
  {
    type: "page",
    name: "route.fdbs.alert",
    key: "default",
    route: "/dashboards/default",
    component: <AlertLazy />,
  },
  {
    name: "route.fdbs.tutorial",
    key: "tutorial",
    route: "/alert/tutorial",
    component: <AlertDemoLazy />,
  },
  {
    type: "page",
    name: "route.fdbs.dashboard",
    key: "dashboard",
    route: "/alert/dashboard",
    component: <AlertDashboardLazy />,
  },
  {
    type: "page",
    name: "route.fdbs.aed.import",
    key: "import",
    route: "/aed/import",
    type: "hidden",
    component: <AedImportLazy />,
  },
  {
    type: "page",
    name: "route.fdbs.history",
    key: "history",
    route: "/alert/history",
    component: <HistoryLazy />,
  },
  {
    type: "page",
    name: "route.fdbs.testerManagement",
    key: "tester",
    route: "/alert/testerManagement",
    component: <TesterLazy />,
  },
  {
    type: "page",
    name: "route.fdbs.citizenForm",
    key: "citizen",
    route: "/alert/citizenAlertForm/:id",
    type: "hidden",
    component: <CitizenAlertOneFormLazy />,
  },
  {
    type: "page",
    name: "route.fdbs.admin.notification",
    key: "admin/notification/list",
    route: "/admin/notification/list",
    component: <NotificationListLazy />,
  },
  {
    type: "page",
    name: "route.fdbs.admin.notification.add",
    key: "admin/notification/add",
    route: "/admin/notification/add",
    component: <NotificationAddLazy />,
    type: "hidden",
  },
];

const referentRoute = [
  {
    type: "page",
    name: "route.fdbs.history",
    key: "history",
    route: "/dashboards/default",
    component: <HistoryLazy />,
  },
  {
    type: "page",
    name: "route.fdbs.citizenForm",
    key: "citizen",
    route: "/alert/citizenAlertForm/:id",
    type: "hidden",
    component: <CitizenAlertOneFormLazy />,
  },
];

const aedManagerRoute = [];

const volunteerRoute = [];

const errorRoute = [
  {
    type: "page",
    name: "route.fdbs.error",
    icon: <Heart size="12px" />,
    key: "default",
    route: "/dashboards/default",
    component: <Error500Lazy />,
  },
];

const fdbsOperateurRoutes = [
  {
    type: "page",
    name: "route.fdbs.alert",
    key: "default",
    route: "/dashboards/default",
    component: <AlertLazy />,
  },
  {
    name: "route.fdbs.tutorial",
    key: "tutorial",
    route: "/alert/tutorial",
    component: <AlertDemoLazy />,
  },
  {
    type: "page",
    name: "route.fdbs.dashboard",
    key: "dashboard",
    route: "/alert/dashboard",
    component: <AlertDashboardLazy />,
  },
];

const fdbsDirectionRoutes = [
  {
    type: "page",
    name: "route.fdbs.alert",
    key: "default",
    route: "/dashboards/default",
    component: <AlertLazy />,
  },
  {
    name: "route.fdbs.tutorial",
    key: "tutorial",
    route: "/alert/tutorial",
    component: <AlertDemoLazy />,
  },
  {
    type: "page",
    name: "route.fdbs.dashboard",
    key: "dashboard",
    route: "/alert/dashboard",
    component: <AlertDashboardLazy />,
  },
  {
    type: "page",
    name: "route.fdbs.history",
    key: "history",
    route: "/alert/history",
    component: <HistoryLazy />,
  },
  {
    type: "page",
    name: "route.fdbs.testerManagement",
    key: "tester",
    route: "/alert/testerManagement",
    component: <TesterLazy />,
  },
  {
    type: "page",
    name: "route.fdbs.citizenForm",
    key: "citizen",
    route: "/alert/citizenAlertForm/:id",
    type: "hidden",
    component: <CitizenAlertOneFormLazy />,
  },
];

const fdbsUpdtPwdRoute = [
  {
    type: "page",
    name: "route.fdbs.updt-pwd",
    icon: <Heart size="12px" />,
    key: "default",
    route: "/dashboards/default",
    component: <UpdtPwdLazy />,
  },
];

const initRoutes = [
  {
    name: "default2",
    key: "default2",
    route: "/authentication/sign-in",
    component: <SignInLazy />,
  },
  {
    name: "resetPassword",
    key: "resetPassword",
    route: "/authentication/reset-password",
    component: <ResetPwdLazy />,
  },
  {
    name: "renewPassword",
    key: "renewPassword",
    route: "/authentication/renew-password",
    component: <RenewPwdLazy />,
  },
  {
    name: "default",
    key: "default",
    route: "/dashboards/default",
    component: <DefaultLazy />,
  },
];

const routesList = {
  0: initRoutes,
  1: adminRoutes,
  100001: fdbsUpdtPwdRoute,
  100002: errorRoute,
  5: fdbsOperateurRoutes,
  6: fdbsDirectionRoutes,
  7: referentRoute,
  8: aedManagerRoute, // Not Use
  9: volunteerRoute, // Not Use
};

export default routesList;
