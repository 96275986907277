import { apiErrorManager } from "helpers/apiErrorManager";
import { messageConstants, ongoingAlertsConstants } from "../constants";
import { alertService } from "services";

function alertsSuccess(values) {
  return { type: ongoingAlertsConstants.ALERTS_SUCCESS, payload: values };
}

function message(msgType, msg) {
  const payload = { msgType, msg };
  return { type: messageConstants.SET_MESSAGE, payload };
}

export const getAlerts =
  (filter = {}) =>
  (dispatch) => {
    return alertService.getAlerts(filter).then(
      (alertsResult) => {
        dispatch(message("", ""));
        dispatch(alertsSuccess(alertsResult));
        return Promise.resolve(alertsResult.alerts);
      },
      (error) => {
        const errorMsg = apiErrorManager(error);
        dispatch(message("error", errorMsg));
        return Promise.reject();
      }
    );
  };
