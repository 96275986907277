import { refreshDataConstants } from "../constants";

const defaultData = "false";

export default (state = { data: defaultData }, action = {}) => {
  switch (action.type) {
    case refreshDataConstants.REFRESH_GLOBAL:
      return {
        ...state,
        data: !state.data,
      };
    default:
      return state;
  }
};
