import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getDayJSAdapterLocale } from "helpers/dateTimeFormating";
import { loadLocale } from "helpers/dateTimeFormating";
import { useTranslation } from "react-i18next";

export const FDBSLocalizationProvider = ({ children }) => {
  const { t } = useTranslation();
  const lang = t("localLanguage");
  const locale = getDayJSAdapterLocale(lang);

  const [isLocaleLoaded, setIsLocaleLoaded] = useState(false);

  useEffect(() => {
    const loadLocaleAsync = async () => {
      await loadLocale(locale);
      setIsLocaleLoaded(true);
    };
    loadLocaleAsync();
  }, [locale]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={isLocaleLoaded ? dayjs.locale(locale) : dayjs.locale("en")}
    >
      {children}
    </LocalizationProvider>
  );
};
