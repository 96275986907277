import { constructOneMonth } from "helpers/dateTimeFormating";
import { historyAlertConstants } from "../constants";
import dayjs from "dayjs";

export const defaultMonth = constructOneMonth();
const startDefaultDate = dayjs(defaultMonth[0]).format("YYYY-MM-DD");
const endDefaultDate = dayjs(defaultMonth[1]).format("YYYY-MM-DD");

const defaultData = {
  list: [],
  count: 0,
  filter: {
    address: "",
    alertType: "",
    startDate: startDefaultDate,
    endDate: endDefaultDate,
    order: "desc",
  },
  pages: 0,
  limit: 20,
};

const initialState = {
  data: defaultData,
};

export default (state = { data: defaultData }, action = {}) => {
  switch (action.type) {
    case historyAlertConstants.LOAD_HISTORY:
      const { list, count } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          list,
          count,
        },
      };
    case historyAlertConstants.UPDATE_FILTER:
      return {
        ...state,
        data: {
          ...state.data,
          filter: {
            ...state.data.filter,
            ...action.payload,
          },
          pages: 0,
        },
      };
    case historyAlertConstants.SET_PAGE:
      const { pages } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          pages,
          filter: {
            ...state.data.filter,
          },
        },
      };
    case historyAlertConstants.SET_LIMIT:
      const { limit } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          limit,
          pages: 0,
          filter: {
            ...state.data.filter,
          },
        },
      };
    default:
      return state;
  }
};
