import API from "helpers/api";
import { indicatorsConstants as constants } from "redux/constants";

/**
 * Récupère des données à afficher sur une carte avec la position des défibrillateurs
 * @returns {JSON} Retourne les stats demandées.
 */
const getAedsHeatmap = async (filter = { area: "" }) => {
  const queryFilter = Object.entries(filter)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  const path = `/services/backoffice/indicators/aedsHeatmap${queryFilter ? `?${queryFilter}` : ""}`;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

/**
 * Récupère la croissance des Bons Samaritains inscrits en fonction des filtres
 * @param {Object} filter - Filtre des stats.
 * @param {string} filter.area - Zone géographique. Vide par défaut
 * @param {string} filter.temporality - Temporalité des données. MONTH par défaut
 * @returns {JSON} Retourne les stats demandées.
 */
const getAlertsGrowth = async (filter = { area: "", temporality: constants.MONTH }) => {
  const queryFilter = Object.entries(filter)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  const path = `/services/backoffice/indicators/alertsGrowth${
    queryFilter ? `?${queryFilter}` : ""
  }`;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

/**
 * Récupère le classement des déclenchements par territoire
 * @returns {JSON} Retourne les stats demandées.
 */
const getAlertsRateRanking = async () => {
  const path = `/services/backoffice/indicators/alertsRateRanking`;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

/**
 * Récupère la répartition des compétences des Bons Samaritains inscrits en fonction des filtres
 * @param {Object} filter - Filtre des stats.
 * @param {string} filter.area - Zone géographique. Vide par défaut
 * @returns {JSON} Retourne les stats demandées.
 */
const getAlertsTypologies = async (filter = { area: "" }) => {
  const queryFilter = Object.entries(filter)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  const path = `/services/backoffice/indicators/alertsType${queryFilter ? `?${queryFilter}` : ""}`;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

/**
 * Récupère la croissance des Bons Samaritains inscrits en fonction des filtres
 * @param {Object} filter - Filtre des stats.
 * @param {string} filter.area - Zone géographique. Vide par défaut
 * @param {string} filter.displayMode - Type de données (Total d'inscrits ou nouveaux inscrits). ACCURED par défaut
 * @param {string} filter.temporality - Temporalité des données. MONTH par défaut
 * @returns {JSON} Retourne les stats demandées.
 */
const getCitizensRespondersGrowth = async (
  filter = { area: "", displayMode: constants.ACCURED, temporality: constants.MONTH }
) => {
  const queryFilter = Object.entries(filter)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  const path = `/services/backoffice/indicators/respondersGrowth${
    queryFilter ? `?${queryFilter}` : ""
  }`;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

/**
 * Récupère des données à afficher sur une carte avec les dernières positions supposés des Bons Samaritains
 * @returns {JSON} Retourne les stats demandées.
 */
const getCitizensRespondersHeatmap = async (filter = { area: "" }) => {
  const queryFilter = Object.entries(filter)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  const path = `/services/backoffice/indicators/respondersHeatmap${
    queryFilter ? `?${queryFilter}` : ""
  }`;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

/**
 * Récupère le classement du nombre de Bons Samaritains par territoire
 * @returns {JSON} Retourne les stats demandées.
 */
const getCitizensRespondersRateRanking = async () => {
  const path = `/services/backoffice/indicators/respondersRateRanking`;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

/**
 * Récupère la répartition des compétences des Bons Samaritains inscrits en fonction des filtres
 * @param {Object} filter - Filtre des stats.
 * @param {string} filter.area - Zone géographique. Vide par défaut
 * @returns {JSON} Retourne les stats demandées.
 */
const getCitizensRespondersSkills = async (filter = { area: "", lang: "fr" }) => {
  const queryFilter = Object.entries(filter)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  const path = `/services/backoffice/indicators/respondersSkills${
    queryFilter ? `?${queryFilter}` : ""
  }`;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

/**
 * Récupère les stats des 24 dernières heures
 * @param {Object} filter - Filtre des stats.
 * @param {string} filter.area - Zone géographique. Vide par défaut
 * @returns {JSON} Retourne les stats demandées.
 */
const getLast24 = async (filter = { area: "" }) => {
  const queryFilter = Object.entries(filter)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  const path = `/services/backoffice/indicators/lookLast24${queryFilter ? `?${queryFilter}` : ""}`;
  const res = await API.get(path);
  const dt = res.data.data;
  return dt;
};

export const indicatorService = {
  getAedsHeatmap,
  getAlertsGrowth,
  getAlertsRateRanking,
  getAlertsTypologies,
  getCitizensRespondersGrowth,
  getCitizensRespondersHeatmap,
  getCitizensRespondersRateRanking,
  getCitizensRespondersSkills,
  getLast24,
};
