import { notificationService } from "services";
import { notificationConstants } from "../constants";

function success(notif) {
  return { type: notificationConstants.SET_NOTIF, payload: notif };
}

export const getNotification = (tag, lang) => (dispatch) => {
  return notificationService.notification(tag, lang).then(
    (message) => {
      dispatch(success(message));
      return Promise.resolve();
    },
    () => {
      return Promise.reject();
    }
  );
};
