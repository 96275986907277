import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Divider, Icon, List, ListItemButton, ListItemText } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Custom styles for the Configurator
import ConfiguratorRoot from "fdbs/elements/Configurator/ConfiguratorRoot";
import { useSoftUIController, setOpenConfigurator } from "context";
import { logout } from "redux/actions/userAction";
import SidenavCollapse from "../Sidenav/SidenavCollapse";
import UserCard from "fdbs/components/Cards/UserCard/UserCard";
import CountriesList from "./CountriesList/CountriesList";

function Configurator({ routes }) {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const { openConfigurator } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  const logoutHandler = () => {
    handleCloseConfigurator();
    reduxDispatch(logout());
  };

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
      let returnValue;

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={t(name)}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={t(name)}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={t(name)}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "hidden") {
        returnValue = "";
      } else if (type === "page") {
        returnValue = (
          <li key={key}>
            <ListItemButton
              component={Link}
              to={route ? route : ""}
              disabled={!route}
              onClick={handleCloseConfigurator}
              sx={{ minHeight: "2.25rem", paddingX: "1rem" }}
            >
              <ListItemText
                primary={t(name)}
                sx={{ "& span": { fontWeight: pathname === route ? "bold" : "regular" } }}
              />
            </ListItemButton>
          </li>
        );
      } else if (type === "title") {
        returnValue = (
          <SoftTypography
            key={key}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {t(title)}
          </SoftTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} />;
      }

      return returnValue;
    }
  );

  return (
    <ConfiguratorRoot
      open={openConfigurator}
      onClose={handleCloseConfigurator}
      anchor="right"
      ownerState={{ openConfigurator }}
    >
      <SoftBox
        display="flex"
        alignItems="center"
        p={2.5}
        sx={{ backgroundColor: (theme) => theme.palette.grey[100], height: "80px" }}
      >
        <Icon
          sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
            fontSize: `2rem!important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: dark.main,
            strokeWidth: "2px",
            cursor: "pointer",
            marginRight: "8px",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
        <UserCard />
      </SoftBox>

      <List sx={{ paddingY: "0.75rem" }}>
        {renderRoutes}
        <Divider sx={{ backgroundColor: "black!important" }} />
        <CountriesList />
        <ListItemButton onClick={logoutHandler}>
          <ListItemText primary={t("menu.logout")} />
        </ListItemButton>
      </List>
    </ConfiguratorRoot>
  );
}

export default Configurator;
