import { testerManagementConstants } from "../constants";

const defaultData = {
  list: [],
};

export default (state = { data: defaultData }, action = {}) => {
  switch (action.type) {
    case testerManagementConstants.INIT:
      return (state.list = [...action.payload]);
    case testerManagementConstants.ADD:
      const newTab = [...state];
      newTab.unshift(action.payload);
      return (state.list = [...newTab]);
    case testerManagementConstants.DELETE:
      const filterTab = [...state].filter((responder) => responder.phone !== action.payload);
      return (state.list = [...filterTab]);

    default:
      return state;
  }
};
