import { useState } from "react";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import { Collapse, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SoftBox from "components/SoftBox";

import { supportedLanguages } from "redux/constants";

export default function CountriesList() {
  const { t } = useTranslation();
  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || "";
  const [openCollapse, setOpenCollapse] = useState(false);

  const changeLanguage = (newLang) => {
    i18n.changeLanguage(newLang);
  };

  return (
    <>
      <ListItemButton onClick={() => setOpenCollapse(!openCollapse)}>
        <ListItemText primary={t("menu.language")} />
        {openCollapse ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openCollapse}>
        {supportedLanguages.map((language) => (
          <ListItemButton key={language.iso} onClick={() => changeLanguage(language.iso)}>
            <ListItemIcon>
              <SoftBox
                component="img"
                pl={1}
                src={language.flagIcon}
                alt={language.iso}
                height="1rem"
              />
            </ListItemIcon>
            <ListItemText
              primary={language.localName}
              sx={{
                "& span": {
                  fontWeight: getCurrentLng().substring(0, 2) === language.iso ? "bold" : "regular",
                },
              }}
            />
          </ListItemButton>
        ))}
        <br />
      </Collapse>
    </>
  );
}
