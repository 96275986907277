import API from "helpers/api";
import { logout } from "redux/actions/userAction";

const setup = (store) => {
  API.interceptors.request.use(
    (config) => {
      const configR = config;
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.token) {
        configR.headers.Authorization = `JWT ${user.token}`;
      }
      return configR;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const { dispatch } = store;
  API.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "services/backOffice/signin/" && err.response) {
        if (err.response.status === 401) {
          dispatch(logout())
            .then(() => {
              window.location.href = `/authentication/sign-in`; // TODO : Find the best way to do this !
            })
            .catch((err2) => {
              console.log(err2);
            });
        }
      }
      return Promise.reject(err);
    }
  );
};
export default setup;
